import React, { PropsWithChildren, ReactNode, useState } from 'react';

import {
    Title,
    Image,
    CardRoot,
    ImageWrapper,
    Date,
    Text,
    Children,
} from './style';
import { formatDate } from '../../lib/util';
import {GatsbyImageProps} from "gatsby-plugin-image";
import {Link} from "../Link";
import {limitString} from "../../util/etc";

export type CardPropsType = Partial<PropsWithChildren<{
    link: string;
    title: ReactNode;
    titleFunc: (hovered: boolean) => ReactNode;
    text: string;
    date: string;
    image: {
        childImageSharp: GatsbyImageProps;
    } | null
    imageYPosition: number;
    showOnlyYear: boolean;
    padding: number;
}>>;

export function Card({
   link,
   title,
   image,
   date,
   text,
   showOnlyYear,
   imageYPosition,
   children,
   titleFunc,
   padding = 3,
}: CardPropsType) {
    const [hovered, setHovered] = useState(false);
    const titleFunction = titleFunc ?? ((_: boolean) => title);

    return (
        <CardRoot>
            {
                !!image
                &&
                <ImageWrapper
                    href={link ?? "/"}
                    yPosition={imageYPosition}
                >
                    <Image
                        image={image!.childImageSharp.gatsbyImageData}
                        alt=""
                    />
                </ImageWrapper>
            }
            {
                !!date
                &&
                <Date>{formatDate(date, !!showOnlyYear)}</Date>
            }
            <Title variant="h4" component="div" padding={padding}>
                <Link
                    href={link ?? "/"}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    {titleFunction(hovered) ?? "No title"}
                </Link>
            </Title>
            {
                !!text
                &&
                <Text>{limitString(text, 100)}...</Text>
            }
            {
                !!children
                &&
                <Children padding={padding}>
                    {children}
                </Children>
            }
        </CardRoot>
    );
};
