module.exports = {
    BLOG_LIST: '/blog',
    BLOG_DETAIL: '/blog/#SLUG#/',
    CONTACTS: '/contacts',
    SKILLS: '/skills',
    PERSONAL_DATA_POLICY: '/personal-data-policy',
    COOKIE_POLICY: '/cookie-policy',
    ABOUT: '/about',
    HOBBIES_LIST: '/hobbies',
    HOBBIES_DETAIL: '/hobbies/#SLUG#/',
    PROJECTS_LIST: '/projects',
    PROJECTS_DETAIL: '/projects/#SLUG#/',
    AI_TOOLS: '/ai-tools/',

    fillTemplate: (template, values) => {
        let result = template;

        if (values) {
            Object.keys(values).forEach((key) => {
                const value = values[key];
                result = result
                    .replace(`#${key}#`, value)
                    .replace(`#${key.toUpperCase()}#`, value);
            });
        }

        return result;
    },
};
