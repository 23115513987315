import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Chip } from 'components/Chip/Chip';
import { Box, Typography } from '@material-ui/core';

import {AIToolList} from "../../enums/aiTools";
import {Card} from "../Card";
import { GridCell, Root, Title, Features, Models, Copyright } from './style';
import { Link } from 'components/Link';

type AIToolsProps = {};

export function AITools(props: AIToolsProps) {

    return (
        <Root>
            <Grid container spacing={5}>
                {AIToolList.map(tool => {
                    const hasLinks = !!(tool.apiLink || tool.docs || tool.ollama?.length);

                    return (
                        <GridCell md={4} sm={6} xs={12} key={tool.name} component="div">
                            <Card
                                padding={5}
                                link={tool.link}
                                titleFunc={
                                    (hovered) => {
                                        return (
                                            <Title>
                                                {
                                                    !!tool.icon
                                                    &&
                                                    <tool.icon hovered={hovered} />
                                                }
                                                {tool.name}
                                            </Title>
                                        );
                                    }
                                }
                            >
                                <Features>
                                    <Typography variant="caption" variant="h4" component="div" style={{marginBottom: '0.5rem'}}>Can do</Typography>
                                    {
                                        tool.features.map(feature => {
                                            return (
                                                <Chip key={feature} label={feature} />
                                            );
                                        })
                                    }
                                </Features>
                                {
                                    tool.modelNames.length > 0
                                    &&
                                    <Box marginTop={2}>
                                        <Typography variant="caption" variant="h4" component="div" style={{marginBottom: '0.5rem'}}>Models</Typography>
                                        <Models>
                                            {
                                                tool.modelNames.map(model => {
                                                    if (model.link) {
                                                        return (
                                                            <Chip key={model.name} label={model.name} link={model.link} />
                                                        );
                                                    }

                                                    return (
                                                        <Chip key={model.name} label={model.name} />
                                                    );
                                                })
                                            }
                                        </Models>
                                    </Box>
                                }
                                {
                                    hasLinks
                                    &&
                                    <Box marginTop={2}>
                                        <Typography variant="caption" component="div">
                                            <Box display="flex" flexWrap={'wrap'} style={{gap: '1rem'}}>
                                                {
                                                    !!tool.docs
                                                    &&
                                                    <div>
                                                        🧐&nbsp;&nbsp;
                                                        <Link href={tool.docs} target="_blank">Documentation</Link>
                                                    </div>
                                                }
                                                {
                                                    !!tool.ollama?.length
                                                    &&
                                                    <Box display="flex" flexWrap={'wrap'} style={{gap: '.4rem'}}>
                                                        ollama:
                                                        {
                                                            tool.ollama.map(ollama => {
                                                                return (
                                                                    <Link key={ollama.name} href={ollama.link} target="_blank">{ollama.name}</Link>
                                                                )
                                                            })
                                                        }
                                                    </Box>
                                                }
                                            </Box>
                                        </Typography>
                                    </Box>
                                }
                                {
                                    !!tool.copyright
                                    &&
                                    <Copyright>
                                        {tool.copyright}
                                    </Copyright>
                                }
                            </Card>
                        </GridCell>
                    );
                })}
            </Grid>
        </Root>
    );
}
