import { ABOUT, BLOG_LIST, CONTACTS, SKILLS, HOBBIES_LIST, PROJECTS_LIST, AI_TOOLS } from './pathTemplates';

export const menu = [
    { text: 'Home', link: '/' },
    { text: 'Blog', link: BLOG_LIST },
    { text: 'Projects', link: PROJECTS_LIST },
    { text: 'Skills', link: SKILLS },
    { text: '⭐ AI Tools', link: AI_TOOLS },
    { text: '3D Art', link: HOBBIES_LIST },
    { text: 'About', link: ABOUT },
    { text: 'Contacts', link: CONTACTS },
];
