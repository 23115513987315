import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import {muiSpacing} from "@gannochenko/ui.styled-components";

export const Root = styled.div`
    margin-bottom: 2rem;
`;

export const GridCell = styled(Grid)`
    margin-bottom: ${muiSpacing(5)};
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    min-height: 56px;

    &>:last-child {
        margin-right: ${muiSpacing(4)};
    }
`;

export const Features = styled.div`
    > * {
        margin-right: ${muiSpacing(1)};
        margin-bottom: ${muiSpacing(1)};
    }
`;

export const Models = styled.div`
    > * {
        margin-right: ${muiSpacing(1)};
        margin-bottom: ${muiSpacing(1)};
    }
`;

export const Copyright = styled.div`
    margin-top: ${muiSpacing(3)};
    font-size: 0.5rem;
    color: #999 !important;
    > * {
        color: #999 !important;
    }
`;
