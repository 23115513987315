import styled, { css } from 'styled-components';
import { muiColor, muiSpacing } from "@gannochenko/ui.styled-components";

export const ChipRoot = styled.div<{
    active?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
    className?: string;
    link?: string;
}>`
    display: inline-flex;
    align-items: center;
    padding: ${muiSpacing(1)} ${muiSpacing(2)};
    border-radius: 99999px;
    background-color: ${muiColor("background.paper")};
    border: 1px solid ${muiColor("background.dark")};
    color: ${muiColor("background.dark")};
    font-size: ${({ theme }) => theme.typography.small.fontSize};
    transition: all 0.2s ease;
    user-select: none;

    text-decoration: none;
    * {
        text-decoration: none;
    }

    ${({ link }) =>
        !!link &&
        css`
            border-color: ${muiColor("primary.main")};
            cursor: pointer;
            &:hover {
                background-color: ${muiColor("primary.main")};
                color: ${muiColor("primary.contrastText")};
                * {
                    color: ${muiColor("primary.contrastText")};
                }
            }
        `}

    ${({ active }) =>
        active &&
        css`
            background-color: ${muiColor("primary.main")};
            color: ${muiColor("primary.contrastText")};
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
            &:hover {
                background-color: ${muiColor("background.paper")};
                color: ${muiColor("primary.main")};
            }
        `}
`;
