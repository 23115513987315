import React from "react";
import { SVGIconProps } from "./type";
import { useTheme } from "@material-ui/core";

export const ClineIcon = ({ size = 56, hovered }: SVGIconProps) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 92 96"
      fill={hovered ? theme.palette.primary.dark : "#24292F"}
      stroke={hovered ? theme.palette.primary.dark : "#24292F"}
      style={{ flex: "0 0 auto", lineHeight: 1, transition: "all 200ms" }}
    >
      <title>Cline</title>
      <g>
        <path d="M65.4492701,16.3 C76.3374701,16.3 85.1635558,25.16479 85.1635558,36.1 L85.1635558,42.7 L90.9027661,54.1647464 C91.4694141,55.2966923 91.4668177,56.6300535 90.8957658,57.7597839 L85.1635558,69.1 L85.1635558,75.7 C85.1635558,86.63554 76.3374701,95.5 65.4492701,95.5 L26.0206986,95.5 C15.1328272,95.5 6.30641291,86.63554 6.30641291,75.7 L6.30641291,69.1 L0.448507752,57.7954874 C-0.14693501,56.6464093 -0.149634367,55.2802504 0.441262896,54.1288283 L6.30641291,42.7 L6.30641291,36.1 C6.30641291,25.16479 15.1328272,16.3 26.0206986,16.3 L65.4492701,16.3 Z M62.9301895,22 L29.189529,22 C19.8723267,22 12.3191987,29.5552188 12.3191987,38.875 L12.3191987,44.5 L7.44288578,53.9634655 C6.84794449,55.1180686 6.85066096,56.4896598 7.45017099,57.6418974 L12.3191987,67 L12.3191987,72.625 C12.3191987,81.9450625 19.8723267,89.5 29.189529,89.5 L62.9301895,89.5 C72.2476729,89.5 79.8005198,81.9450625 79.8005198,72.625 L79.8005198,67 L84.5682187,57.6061395 C85.1432011,56.473244 85.1458141,55.1345713 84.5752587,53.9994398 L79.8005198,44.5 L79.8005198,38.875 C79.8005198,29.5552188 72.2476729,22 62.9301895,22 Z" fill-rule="nonzero"/>
        <circle cx="45.7349843" cy="11" r="11"/>
      </g>
      <rect stroke-width="8" x="31" y="44.5" width="5" height="22" rx="2.5"/>
      <rect stroke-width="8" x="55" y="44.5" width="5" height="22" rx="2.5"/>
    </svg>
  );
};
