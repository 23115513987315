import React from "react";
import { SVGIconProps } from "./type";
import { useTheme } from "@material-ui/core";

export const HeyGenIcon = ({ size = 56, hovered }: SVGIconProps) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill={hovered ? theme.palette.primary.dark : "#104d4d"}
      style={{ flex: "0 0 auto", lineHeight: 1, transition: "all 200ms" }}
    >
      <title>HeyGen</title>
      <path d="M5.886 23.713c0 0 .431 1.293 1.197.263.766-1.029 5.888-10.221 5.888-10.221s1.198-2.126 2.22-2.907c.253-.193.272-.562.031-.769-.637-.547-1.782-1.377-3.041-1.566-1.099-.165-2.441-.862-4.309 1.101s-3.902 6.367-3.04 10.556L5.886 23.713zM12.532 7.277c0 0 1.349.416 2.936 1.404 1.691 1.053 18.128 10.404 18.128 10.404s1.404.734.926-.957c-.479-1.691-5.074-8.521-5.074-8.521s-5.011-6.511-11.713-5.394c0 0-4.181.383-5.617 1.755C12.117 5.968 11.351 6.67 12.532 7.277zM11.287 43.181c0 0 1.819 1.819 1.947-.862l-.255-23.936c0 0-.574-.894-1.372.319-.798 1.213-4.181 7.34-4.181 7.34s-2.074 3-1.564 7.245 2.17 6.67 3.83 8.457L11.287 43.181zM31.745 10.755l6.578 11.446c.356.62.599 1.298.716 2.003v0c.078.465.559.744.999.574 1.362-.526 3.877-1.899 3.993-4.895.131-3.374-4.776-7.408-5.681-7.883-.858-.451-3.835-2.423-5.549-2.726C31.959 9.126 31.321 10.012 31.745 10.755zM14.319 39.223c0 0 .032-.926.766-.543.734.383 1.955.303 1.955.303h13.285c0 0 1.261.08.654.973s-3.766 5.521-9.926 6c0 0-3.989.16-5.266-1.468S14.319 40.979 14.319 39.223zM22.968 36.096c0 0-1.38.71.128 1.38l8.747.064c1.008.007 2.013-.101 2.988-.355 1.765-.46 4.417-1.476 6.52-3.675 3.511-3.67 3.552-6.477 3.606-8.936.011-.49.142-1.701-1.947-.223C38.32 27.668 22.968 36.096 22.968 36.096z"/>
    </svg>
  );
};
