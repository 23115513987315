import React from 'react';

export const authorName = 'Sergei Gannochenko';

const startedIn = 2005; // the year I entered the University I already kind of knew Perl, C and TurboPascal, so...

export const AuthorIntro = () => {
        const now = new Date(Date.now());
        const diff = now.getFullYear() - startedIn;

        return (
            <>
                    Business-focused product engineer, <nobr>in ❤️ with amazing products.</nobr>
                    <br />
                    Golang/Node, React, TypeScript, Docker/K8s, AWS/GCP, NextJS.
                    <br />
                    {diff}+ years in dev.
            </>
        );
};
