import React from "react";
import { SVGIconProps } from "./type";
import { useTheme } from "@material-ui/core";

export const CursorIcon = ({ size = 56, hovered }: SVGIconProps) => {
  const theme = useTheme();

  const fillColor = hovered ? theme.palette.primary.dark : "#000";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fillColor}
      style={{ flex: "0 0 auto", lineHeight: 1, transition: "all 200ms" }}
    >
      <title>Cursor</title>
      <path d="M11.925 24l10.425-6-10.425-6L1.5 18l10.425 6z" fill="url(#lobe-icons-cursorundefined-fill-0)"></path>
      <path d="M22.35 18V6L11.925 0v12l10.425 6z" fill="url(#lobe-icons-cursorundefined-fill-1)"></path>
      <path d="M11.925 0L1.5 6v12l10.425-6V0z" fill="url(#lobe-icons-cursorundefined-fill-2)"></path>
      <path d="M22.35 6L11.925 24V12L22.35 6z" fill={hovered ? theme.palette.primary.light : "#555"}></path>
      <path d="M22.35 6l-10.425 6L1.5 6h20.85z"></path>
      <defs>
        <linearGradient gradientUnits="userSpaceOnUse" id="lobe-icons-cursorundefined-fill-0" x1="11.925" x2="11.925" y1="12" y2="24">
          <stop offset=".16" stop-color={fillColor} stop-opacity=".39"></stop>
          <stop offset=".658" stop-color={fillColor} stop-opacity=".8"></stop>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="lobe-icons-cursorundefined-fill-1" x1="22.35" x2="11.925" y1="6.037" y2="12.15">
          <stop offset=".182" stop-color={fillColor} stop-opacity=".31"></stop>
          <stop offset=".715" stop-color={fillColor} stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="lobe-icons-cursorundefined-fill-2" x1="11.925" x2="1.5" y1="0" y2="18">
          <stop stop-color={fillColor} stop-opacity=".6"></stop>
          <stop offset=".667" stop-color={fillColor} stop-opacity=".22"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
