// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-detail-blog-detail-tsx": () => import("./../../../src/components/BlogDetail/BlogDetail.tsx" /* webpackChunkName: "component---src-components-blog-detail-blog-detail-tsx" */),
  "component---src-components-hobbies-detail-hobbies-detail-tsx": () => import("./../../../src/components/HobbiesDetail/HobbiesDetail.tsx" /* webpackChunkName: "component---src-components-hobbies-detail-hobbies-detail-tsx" */),
  "component---src-components-projects-detail-projects-detail-tsx": () => import("./../../../src/components/ProjectsDetail/ProjectsDetail.tsx" /* webpackChunkName: "component---src-components-projects-detail-projects-detail-tsx" */),
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-ai-tools-mdx": () => import("./../../../src/pages/ai-tools.mdx" /* webpackChunkName: "component---src-pages-ai-tools-mdx" */),
  "component---src-pages-blog-mdx": () => import("./../../../src/pages/blog.mdx" /* webpackChunkName: "component---src-pages-blog-mdx" */),
  "component---src-pages-contacts-mdx": () => import("./../../../src/pages/contacts.mdx" /* webpackChunkName: "component---src-pages-contacts-mdx" */),
  "component---src-pages-cookie-policy-mdx": () => import("./../../../src/pages/cookie-policy.mdx" /* webpackChunkName: "component---src-pages-cookie-policy-mdx" */),
  "component---src-pages-hobbies-mdx": () => import("./../../../src/pages/hobbies.mdx" /* webpackChunkName: "component---src-pages-hobbies-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-personal-data-policy-mdx": () => import("./../../../src/pages/personal-data-policy.mdx" /* webpackChunkName: "component---src-pages-personal-data-policy-mdx" */),
  "component---src-pages-projects-mdx": () => import("./../../../src/pages/projects.mdx" /* webpackChunkName: "component---src-pages-projects-mdx" */),
  "component---src-pages-skills-mdx": () => import("./../../../src/pages/skills.mdx" /* webpackChunkName: "component---src-pages-skills-mdx" */),
  "component---src-pages-skills-soft-mdx": () => import("./../../../src/pages/skills/soft.mdx" /* webpackChunkName: "component---src-pages-skills-soft-mdx" */)
}

