import React from 'react';
import { ChipPropsType } from './proptypes';
import { ChipRoot } from './style';
import { Link } from 'components/Link';

export function Chip({
    label,
    onClick,
    active = false,
    disabled = false,
    className,
    link,
}: ChipPropsType) {
    const handleClick = () => {
        if (!disabled && onClick) {
            onClick();
        }
    };

    const hasLink = !!link;

    return (
        <ChipRoot
            onClick={handleClick}
            active={active}
            disabled={disabled}
            className={className}
            link={link}
        >
            {
                hasLink
                &&
                <Link to={link}>{label}</Link>
            }
            {!hasLink && label}
        </ChipRoot>
    );
}
