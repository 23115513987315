import React from "react";
import { SVGIconProps } from "./type";
import { useTheme } from "@material-ui/core";

export const SunoIcon = ({ size = 56, hovered }: SVGIconProps) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={hovered ? theme.palette.primary.dark : "#000"}
      style={{ flex: "0 0 auto", lineHeight: 1, transition: "all 200ms" }}
    >
      <title>Suno</title>
      <path d="M16.5 0C20.642 0 24 5.373 24 12h-9c0 6.627-3.358 12-7.5 12C3.358 24 0 18.627 0 12h9c0-6.627 3.358-12 7.5-12z"></path>
    </svg>
  );
};
