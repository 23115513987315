import React from "react";
import { SVGIconProps } from "./type";
import { useTheme } from "@material-ui/core";

export const ElevenLabsIcon = ({ size = 56, hovered }: SVGIconProps) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={hovered ? theme.palette.primary.dark : "#000"}
      style={{ flex: "0 0 auto", lineHeight: 1, transition: "all 200ms" }}
    >
      <title>Claude</title>
      <path d="M12.82 8H14.44V16H12.82V8Z" />
      <path d="M9.52 8H11.14V16H9.52V8Z" />
    </svg>
  );
};
