import { OpenAIIcon, PerplexityIcon, DeepSeekIcon, QwenIcon, MidjourneyIcon, SunoIcon, ClaudeIcon, ElevenLabsIcon, ClineIcon, CursorIcon, HeyGenIcon } from "components/SVG/icons";
import { SVGIconProps } from "components/SVG/icons/type";
import React, { FunctionComponent, ReactNode } from "react";

export enum FeatureEnum {
    TEXT_REWRITE = "📝 Text Rewrite",
    CRAWLING = "🔍 Crawling Abilities",
    IMAGE_GENERATION = "🌆 Image Generation",
    VIDEO_GENERATION = "🎥 Video Generation",
    AUDIO_GENERATION = "🔊 Audio Generation",
    CODE_ASSISTANCE = "🤖 Code Assistance",
    REAL_TIME_INFO = "ℹ️ Real-time Information Retrieval",
    VOICE_MODE = "🎤 Voice Mode",
    MEMORY_FEATURE = "🧑‍🏫 Memory Feature",
    SUMMARIZATION = "😎 Summarization",
    QUESTION_ANSWERING = "🙋‍♂️ Question Answering",
    REASONING = "🧠 Advanced Reasoning",
    MULTILINGUAL_SUPPORT = "𐦉 Multilingual Support",
    MATHEMATICS = "🧮 Mathematics Capabilities",
    OPEN_SOURCE = "🔥 Open-source Availability",
    EFFICIENCY = "🥊 Efficient Model Training",
    ADVANCED_PROMPTING = "📕 Advanced Prompting",
    STYLE_VARIETY = "💅 Style Variety",
    HIGH_RESOLUTION_OUTPUT = "🚛 High-Resolution Output",
    AGENTS = "🕵️ Agents",
    API = "🔌 API Integrations",
}

type ModelInfo = {
    name: string;
    link?: string;
};

type AIProduct = {
    name: string;
    modelNames: ModelInfo[];
    features: FeatureEnum[];
    icon?: FunctionComponent<SVGIconProps>;
    link?: string;
    apiLink?: string;
    copyright?: ReactNode;
    docs?: string;
    ollama?: ModelInfo[];
};

export const AIToolList: AIProduct[] = [
    {
        name: "ChatGPT",
        modelNames: [
            { name: "GPT-4o" },
            { name: "o1" },
            { name: "o3-mini" },
            { name: "o3-mini-high" },
            { name: "dall-e-3" },
        ],
        features: [
            FeatureEnum.TEXT_REWRITE,
            FeatureEnum.REASONING,
            FeatureEnum.REAL_TIME_INFO,
            FeatureEnum.VOICE_MODE,
            FeatureEnum.MEMORY_FEATURE,
            FeatureEnum.CODE_ASSISTANCE,
            FeatureEnum.MULTILINGUAL_SUPPORT,
            FeatureEnum.API,
        ],
        icon: OpenAIIcon,
        link: "https://chatgpt.com/",
        docs: "https://platform.openai.com/docs/overview"
    },
    {
        name: "Sora",
        modelNames: [
            { name: "Sora" },
            { name: "Sora Turbo" }
        ],
        features: [
            FeatureEnum.VIDEO_GENERATION,
        ],
        icon: OpenAIIcon,
        link: "https://sora.com/",
    },
    {
        name: "Perplexity",
        modelNames: [
            { name: "sonar-reasoning-pro" },
            { name: "sonar-reasoning" },
            { name: "sonar-pro" },
            { name: "sonar" },
        ],
        features: [
            FeatureEnum.TEXT_REWRITE,
            FeatureEnum.CRAWLING,
            FeatureEnum.REAL_TIME_INFO,
            FeatureEnum.SUMMARIZATION,
            FeatureEnum.QUESTION_ANSWERING,
            FeatureEnum.API,
        ],
        icon: PerplexityIcon,
        link: "https://perplexity.ai",
        docs: "https://docs.perplexity.ai"
    },
    {
        name: "DeepSeek",
        modelNames: [
            { name: "DeepSeek-V3", link: "https://huggingface.co/deepseek-ai/DeepSeek-V3" },
            { name: "DeepSeek-R1", link: "https://huggingface.co/deepseek-ai/DeepSeek-R1" }
        ],
        features: [
            FeatureEnum.TEXT_REWRITE,
            FeatureEnum.REASONING,
            FeatureEnum.EFFICIENCY,
            FeatureEnum.OPEN_SOURCE,
            FeatureEnum.MULTILINGUAL_SUPPORT,
            FeatureEnum.API,
        ],
        icon: DeepSeekIcon,
        link: "https://www.deepseek.com",
        docs: "https://api-docs.deepseek.com/",
        ollama: [
            { name: "Deepseek-V3", link: "https://ollama.com/library/deepseek-v3"},
            { name: "Deepseek-R1", link: "https://ollama.com/library/deepseek-r1"},
        ],
    },
    {
        name: "Qwen",
        modelNames: [
            { name: "Qwen 2.5", link: "https://huggingface.co/Qwen" },
        ],
        features: [
            FeatureEnum.TEXT_REWRITE,
            FeatureEnum.IMAGE_GENERATION,
            FeatureEnum.VIDEO_GENERATION,
            FeatureEnum.CODE_ASSISTANCE,
            FeatureEnum.MATHEMATICS,
            FeatureEnum.OPEN_SOURCE,
            FeatureEnum.MULTILINGUAL_SUPPORT
        ],
        icon: QwenIcon,
        link: "https://qwenlm.ai",
        apiLink: "",
        ollama: [
            { name: "Qwen 2.5", link: "https://ollama.com/library/qwen2.5"},
        ],
    },
    {
        name: "Midjourney",
        modelNames: [
            { name: "Midjourney 6" },
            { name: "Niji 6" },
        ],
        features: [
            FeatureEnum.IMAGE_GENERATION,
            FeatureEnum.ADVANCED_PROMPTING,
            FeatureEnum.STYLE_VARIETY,
            FeatureEnum.HIGH_RESOLUTION_OUTPUT
        ],
        icon: MidjourneyIcon,
        link: "https://midjourney.com",
        docs: "https://docs.midjourney.com/"
    },
    {
        name: "Suno",
        modelNames: [
            { name: "Chirp-v1" },
            { name: "Chirp-v2" },
            { name: "Bark", link: "https://huggingface.co/suno/bark" }
        ],
        features: [
            FeatureEnum.AUDIO_GENERATION,
            FeatureEnum.OPEN_SOURCE,
        ],
        icon: SunoIcon,
        link: "https://suno.com/",
        apiLink: ""
    },
    {
        name: "Claude",
        modelNames: [
            { name: "Claude 3 Opus" },
            { name: "Claude 3.5 Sonnet" },
            { name: "Claude 3 Haiku" }
        ],
        features: [
            FeatureEnum.TEXT_REWRITE,
            FeatureEnum.REASONING,
            FeatureEnum.SUMMARIZATION,
            FeatureEnum.QUESTION_ANSWERING,
            FeatureEnum.MULTILINGUAL_SUPPORT,
            FeatureEnum.API,
        ],
        icon: ClaudeIcon,
        link: "https://claude.ai",
        docs: "https://docs.anthropic.com/"
    },
    {
        name: "VSCode Cline",
        modelNames: [],
        features: [
            FeatureEnum.CODE_ASSISTANCE,
            FeatureEnum.TEXT_REWRITE,
            FeatureEnum.AGENTS,
        ],
        icon: ClineIcon,
        link: "https://github.com/cline/cline",
        apiLink: ""
    },
    {
        name: "Eleven Labs",
        modelNames: [
            { name: "Eleven Multilingual v2" }
        ],
        features: [
            FeatureEnum.AUDIO_GENERATION,
            FeatureEnum.VOICE_MODE,
            FeatureEnum.API,
        ],
        icon: ElevenLabsIcon,
        link: "https://elevenlabs.io/",
        docs: "https://elevenlabs.io/docs/"
    },
    {
        name: "HeyGen",
        modelNames: [
            { name: "HeyGen AI Avatars" }
        ],
        features: [
            FeatureEnum.VIDEO_GENERATION,
            FeatureEnum.AUDIO_GENERATION,
            FeatureEnum.API,
        ],
        icon: HeyGenIcon,
        link: "https://www.heygen.com/",
        docs: "https://docs.heygen.com/docs/quick-start",
        copyright: (<><a target="_blank" href="https://icons8.com/icon/1sRssQDx86sp/heygen">Heygen</a> Icon from <a target="_blank" href="https://icons8.com">Icons8</a></>)
    },
    {
        name: "Cursor",
        modelNames: [
            { name: "Cursor AI" }
        ],
        features: [
            FeatureEnum.CODE_ASSISTANCE,
            FeatureEnum.TEXT_REWRITE
        ],
        icon: CursorIcon,
        link: "https://cursor.sh/",
        apiLink: ""
    },
    {
        name: "Mistral",
        modelNames: [
            { name: "Mistral Large" },
            { name: "Pixtral Large" },
            { name: "Ministral 3B" },
            { name: "Ministral 8B" },
            { name: "Codestral" },
            { name: "Mistral Small" },
            { name: "Pixtral" },
            { name: "Mistral NeMo" },
            { name: "Codestral Mamba" },
            { name: "Mathstral 7b" },
        ],
        features: [
            FeatureEnum.TEXT_REWRITE,
            FeatureEnum.REASONING,
            FeatureEnum.SUMMARIZATION,
            FeatureEnum.QUESTION_ANSWERING,
            FeatureEnum.MULTILINGUAL_SUPPORT,
            FeatureEnum.MATHEMATICS,
            FeatureEnum.CODE_ASSISTANCE,
            FeatureEnum.OPEN_SOURCE,
            FeatureEnum.EFFICIENCY,
            FeatureEnum.ADVANCED_PROMPTING,
            FeatureEnum.API,
        ],
        //icon: MistralIcon, // Replace with the appropriate icon reference
        link: "https://mistral.ai/",
        docs: "https://docs.mistral.ai/",
        ollama: [
            { name: "Mistral 7b", link: "https://ollama.com/library/mistral" },
        ],
    },
    {
        name: "Stable Diffusion",
        modelNames: [
            { name: "Stable Diffusion 1.5" },
            { name: "Stable Diffusion 2.1" },
            { name: "Stable Diffusion XL (SDXL)" }
        ],
        features: [
            FeatureEnum.IMAGE_GENERATION,
            FeatureEnum.OPEN_SOURCE,
            FeatureEnum.EFFICIENCY,
            FeatureEnum.API,
            FeatureEnum.STYLE_VARIETY,
            FeatureEnum.HIGH_RESOLUTION_OUTPUT
        ],
        //icon: StableDiffusionIcon, // Replace with appropriate icon reference
        link: "https://stablediffusionweb.com/",
        docs: "https://platform.stability.ai/docs"
    },
    {
        name: "Llama",
        modelNames: [
            { name: "Llama 2 7B" },
            { name: "Llama 2 13B" },
            { name: "Llama 2 70B" },
            { name: "Llama 3 (Upcoming)" }
        ],
        features: [
            FeatureEnum.TEXT_REWRITE,
            FeatureEnum.REASONING,
            FeatureEnum.SUMMARIZATION,
            FeatureEnum.QUESTION_ANSWERING,
            FeatureEnum.MULTILINGUAL_SUPPORT,
            FeatureEnum.MATHEMATICS,
            FeatureEnum.CODE_ASSISTANCE,
            FeatureEnum.OPEN_SOURCE,
            FeatureEnum.EFFICIENCY,
            FeatureEnum.ADVANCED_PROMPTING,
            FeatureEnum.API
        ],
        //icon: LlamaIcon, // Replace with appropriate icon reference
        link: "https://ai.meta.com/llama/",
        docs: "https://github.com/facebookresearch/llama"
    },
    {
        name: "Gemma",
        modelNames: [
            { name: "Gemma 2B" },
            { name: "Gemma 7B" }
        ],
        features: [
            FeatureEnum.TEXT_REWRITE,
            FeatureEnum.REASONING,
            FeatureEnum.SUMMARIZATION,
            FeatureEnum.QUESTION_ANSWERING,
            FeatureEnum.MULTILINGUAL_SUPPORT,
            FeatureEnum.MATHEMATICS,
            FeatureEnum.CODE_ASSISTANCE,
            FeatureEnum.OPEN_SOURCE,
            FeatureEnum.EFFICIENCY,
            FeatureEnum.ADVANCED_PROMPTING,
            FeatureEnum.API
        ],
        //icon: GemmaIcon, // Replace with appropriate icon reference
        link: "https://ai.google.dev/gemma",
        docs: "https://ai.google.dev/gemma/docs"
    },
    {
        name: "Gemini",
        modelNames: [
            { name: "Gemini 1.0" },
            { name: "Gemini 1.5" }
        ],
        features: [
            FeatureEnum.TEXT_REWRITE,
            FeatureEnum.REASONING,
            FeatureEnum.REAL_TIME_INFO,
            FeatureEnum.VOICE_MODE,
            FeatureEnum.MEMORY_FEATURE,
            FeatureEnum.CODE_ASSISTANCE,
            FeatureEnum.MULTILINGUAL_SUPPORT,
            FeatureEnum.IMAGE_GENERATION,
            FeatureEnum.VIDEO_GENERATION,
            FeatureEnum.AUDIO_GENERATION,
            FeatureEnum.SUMMARIZATION,
            FeatureEnum.QUESTION_ANSWERING,
            FeatureEnum.MATHEMATICS,
            FeatureEnum.ADVANCED_PROMPTING,
            FeatureEnum.API
        ],
        //icon: GeminiIcon, // Replace with appropriate icon reference
        link: "https://gemini.google.com/",
        docs: "https://ai.google.dev/"
    },    
    {
        name: "AgentGPT",
        modelNames: [
            { name: "AgentGPT" }
        ],
        features: [
            FeatureEnum.TEXT_REWRITE,
            FeatureEnum.REASONING,
            FeatureEnum.QUESTION_ANSWERING,
            FeatureEnum.MULTILINGUAL_SUPPORT,
            FeatureEnum.CODE_ASSISTANCE,
            FeatureEnum.OPEN_SOURCE,
            FeatureEnum.ADVANCED_PROMPTING,
            FeatureEnum.REAL_TIME_INFO,
            FeatureEnum.AGENTS,
            FeatureEnum.API,
        ],
        //icon: AgentGPTIcon, // Replace with appropriate icon reference
        link: "https://agentgpt.reworkd.ai/",
        docs: "https://docs.reworkd.ai/introduction"
    },
];
